import React, { useState, useReducer } from "react";
import { useHistory } from "react-router";
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input";
import { Link } from 'react-router-dom';
import signupMethod from '../methods/signup';

const actionsType = {
    PENDING: 'PENDING',
    RESOLVED: 'RESOLVED',
    REJECTED: 'REJECTED'
}


const userReducer = (state, action) => {
    console.log(action);
    switch(action.type) {
        case actionsType.PENDING: {
            return {status: actionsType.PENDING, error: null}
          }
          case actionsType.RESOLVED: {
            return {status: actionsType.RESOLVED, error: null}
          }
          case actionsType.REJECTED: {
            return {status: actionsType.REJECTED, error: action.error}
          }
          default: {
            throw new Error(`Unhandled action type: ${action.type}`)
          }
    }
}

const Signup = () => {
		const history = useHistory();
		const [{ status, error }, dispatch] = useReducer(userReducer, { status: 'idle', error: null});
		const [email, setEmail] = useState('');
		const [password, setPassword] = useState('');
		const [lastName, setLastName] = useState('');
		const [firstName, setFirstName] = useState('');
		const [phoneLine, setPhoneLine] = useState('');
		const [region, setRegion] = useState('');
		const [confirmPassword, setConfirmPassword] = useState('');
		const [toggledPassword, setToggledPassword] =useState(false);
		const [toggledConfirm, setToggledConfirm] =useState(false);

		const handleSubmit = (e) => {
			e.preventDefault();
			const  promise = signupMethod({ email, password, firstName, phoneNumber: phoneLine, region, lastName});
			if(!promise) {
				return;
			}
			dispatch({ type: actionsType.PENDING})
			promise.then((data) => {
				dispatch({ type: actionsType.RESOLVED, token: data.data.data})
				history.push({pathname:  "/verify"});
			}).catch(error => {
				dispatch({type: actionsType.REJECTED, error: error.response.data.message})
			})
		}
    return (
        <div className="Auth">
					<h1 className="logo">CINETIE</h1>
					<div className="parent signup">
						<form onSubmit={handleSubmit}>
								<h1>Welcome</h1>
								<div className="small">
									<div className="input-field">
										<input 
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
											name="firstName"
											placeholder="first name"/>
									</div>
									<div className="input-field">
										<input 
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
											name="lastName"
											placeholder="last name"/>
									</div>
									<div className="input-field">
										<input 
											value={region}
											onChange={(e) => setRegion(e.target.value)}
											name="region"
											placeholder="Region"/>
									</div>
								</div>
								
								<div className="coller">
									<PhoneInput
										placeholder="78 3456 789"
										value={phoneLine}
										onChange={(e) => setPhoneLine(e)}
										defaultCountry="RW"
										/>
									<br/>
								</div>

								<div className="input-field">
									<input 
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										name="email"
										id="email" 
										placeholder="Email"/>
								</div>

								<div className="input-field">
									<input 
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										id = "password"
										name = "password"
										type = { toggledPassword ? "text" : "password" } 
										placeholder = "Password"
										/>
									{ 
										toggledPassword ? 
										<i onClick={()=> setToggledPassword(!toggledPassword)} className="fas fa-eye-slash"></i>	:
										<i onClick={()=> setToggledPassword(!toggledPassword)} className="fas fa-eye"></i>
									}
								</div>
								<div className="input-field">
									<input 
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										id = "confirmPassword"
										name = "confirmPassword"
										type = { toggledConfirm ? "text" : "password" } 
										placeholder="Confirm Password"
										/>
									{ 
										toggledConfirm ? 
										<i onClick={()=> setToggledConfirm(!toggledConfirm)} className="fas fa-eye-slash"></i>	:
										<i onClick={()=> setToggledConfirm(!toggledConfirm)} className="fas fa-eye"></i>
									}
								</div>
								{	password !== "" && confirmPassword !== "" && password !== confirmPassword ?
									<small className="match-error">Passwords Don't Match</small> : null
								}
								

								<p className="error">{ error && <> <span>⚠️</span>  { error } </> }</p>

								<button 
									disabled={ 
										email === "" || password === "" || confirmPassword === ""
											? true : false || status === actionsType.PENDING }
									>
									{
										status === actionsType.PENDING	?
										<i className="spiner fas fa-spinner"></i> :
										<span>Sign Up</span>
									}
								</button>
								<p className="mt-3 text-muted">Already a member ? <Link to="signin"> Sign in here.</Link> </p>
						</form>
						<section></section>		
					</div>
				</div>
    )
}
export default Signup