import React, { useState, useReducer, useEffect } from 'react';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import signinMethod from '../methods/signin';

const actionsType = {
    PENDING: 'PENDING',
    RESOLVED: 'RESOLVED',
    REJECTED: 'REJECTED'
}


const userReducer = (_, action) => {
    switch(action.type) {
        case actionsType.PENDING: {
            return {status: actionsType.PENDING, token: null, error: null}
          }
          case actionsType.RESOLVED: {
            return {status: actionsType.RESOLVED, token: action.token, error: null}
          }
          case actionsType.REJECTED: {
            return {status: actionsType.REJECTED, token: null, error: action.error}
          }
          default: {
            throw new Error(`Unhandled action type: ${action.type}`)
          }
    }
}


const Signin = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [toggled, setToggled] = useState(false);
    const [{ token, status, error }, dispatch] = useReducer(userReducer, { status: 'idle', token: null, error: null })
	useEffect(() => {
        if(token)
		localStorage.setItem('amb-token', token);
	}, [token])
    const handleSubmit = (e) => {
        e.preventDefault();
        const  promise = signinMethod({ email, password});
        if(!promise) {
            return;
        }
        dispatch({ type: actionsType.PENDING})
        promise.then((data) => {
            Promise.resolve(dispatch({ type: actionsType.RESOLVED, token: data.data.data }))
            .then(()=>history.push({pathname:  "/dashboard"}))
        }).catch(error => {
            dispatch({type: actionsType.REJECTED, error: error.response.data.message})
        })
    }
    return (
        <div className="Auth">
				<h1 className="logo">CINETIE</h1>
				<div className="parent">
					<form onSubmit={handleSubmit}>
                        <h1>Welcome back</h1>
						<div className="input-field">
							<input 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
								name="email"
								id="email" 
								placeholder="Email"/>
						</div>
						<div className="input-field">
							<input 
                            value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={ toggled ? "text" : "password"} 
								id="password"
								name="password"
								placeholder="Password"
								/>
							{ 
								toggled ? 
								<i onClick={()=> setToggled(!toggled)} className="fas fa-eye-slash"></i>	:
								<i onClick={()=> setToggled(!toggled)} className="fas fa-eye"></i>
							}
						</div>
						<div>
							<Link className="forgot" to="passwordReset">Forgot Password ?</Link>
						</div>
                        <p className="error">{ error && <> <span>⚠️</span>  { error } </> }</p>
						
						<button disabled={ email === "" || password === "" ? true :false }>
							{
								status === 'PENDING'	?
                        		<i className="spiner fas fa-spinner"></i> :
								<span>Sign In</span>
							}
						</button>
						<p>First time?  <Link to="signup">Sign Up Now</Link></p>
					</form>
					<section>
					</section>
				</div>
			</div>
    )
}

export default Signin;