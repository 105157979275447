import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AmbassadorSignin from './views/signin';
import Signup from './views/signup';
import ErrorNotFound from './views/notfound'
import Verify from './views/verify'
import Dashboard from './views/dashboard';

window.addEventListener('pageshow', myFunction);

function myFunction(event) {
  // if the page was cached, it will remove the cach
  if (event.persisted) {
    window.location.reload(true)
  }
}

function App() {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={AmbassadorSignin} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path="/verify" component={Verify} />
        <PrivateRoute exact path="/signin" component={AmbassadorSignin} />
        <PrivateRoute exact path="/signup" component={Signup} />
        <Route component={ErrorNotFound} />
      </Switch>
    </Router>
  );
}

export default App;