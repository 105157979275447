const getInfo =  async() => {
    const token = window.localStorage.getItem('amb-token');
    const option = {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json; charset=UTF-8',
        }
    }
    return await fetch(`${process.env.REACT_APP_API_URL}/ambassadors/info`, option).then(async response => {
        const data = await response.json();
        return data;
    })
}

export default getInfo
