import axios from 'axios';


const signin = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/ambassadors/login`,
			data
			, {
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			})
}
export default signin;