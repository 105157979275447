import React, { useEffect, useReducer } from "react";
import { Redirect, useHistory } from "react-router-dom";
import getInfo from "../methods/getInfo";

const actionsType = {
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED",
};

const infoReducer = (_, action) => {
  switch (action.type) {
    case actionsType.PENDING: {
      return { status: actionsType.PENDING, info: null, error: null };
    }
    case actionsType.RESOLVED: {
      return { status: actionsType.RESOLVED, info: action.info, error: null };
    }
    case actionsType.REJECTED: {
      return { status: actionsType.REJECTED, info: null, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default function Dashboard() {
  const history = useHistory();
  const [{ status, info }, dispatch] = useReducer(infoReducer, {
    info: null,
    status: "idle",
    error: null,
  });
  useEffect(() => {
    const promise = getInfo();
    if (!promise) {
      return;
    }
    dispatch({ type: actionsType.PENDING });
    promise
      .then((data) => {
        if (data.statusCode !== 200) {
          dispatch({ type: actionsType.REJECTED, error: data.message });
        }
        dispatch({ type: actionsType.RESOLVED, info: data.data });
      })
      .catch((error) => {
        dispatch({
          type: actionsType.REJECTED,
          error: error.response.data.message,
        });
      });
  }, [history]);

  const signOut = () => {
    window.localStorage.removeItem("amb-token");
    history.push("/signin");
  };

  if (status === actionsType.REJECTED) {
    window.localStorage.removeItem("amb-token");
    return <Redirect to="/signin" />;
  }

  return (
    <div className="dashboard-background">
      <div className="dashboard-header">
        <div className="dashboard-logo">
          <h1>CINETIE</h1>
          <div className="short-border-line"></div>
          <p className="ambassador-dashboard">ambassador</p>
        </div>
        <button className="dashboard-signout" onClick={signOut}>
          sign out
        </button>
      </div>
      <div className="dashboard-promocode">
        <p>
          Your promo code is: <span>{info?.promoCode}</span>
        </p>
      </div>
      <div className="dashboard-stats">
        <div className="dashbord-section__title">
          <h2>USAGE STATS</h2>
          <div className="short-border-line"></div>
        </div>
        <div className="dashboard-stats__section">
          <div>
            <div>
              <p>today</p> <span>{info?.stats?.today}</span>
            </div>
            <div>
              <p>this month</p> <span>{info?.stats?.monthly}</span>
            </div>
            <div>
              <p>this year</p> <span>{info?.stats?.yearly}</span>
            </div>
          </div>
          <div></div>
          <div>
            <h4>All time usage</h4>
            <p>{info?.stats?.totalUsage}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
