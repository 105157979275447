import React from 'react';
import { Link } from 'react-router-dom';
class Verify extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="whiteBg pt-5 hFullSection">
                    <div className="container pt-5">
                        <h2 className="text-primary text-center font-weight-bold">CINETIE</h2>
                        <div className="card col-lg-6 mx-auto mt-5 text-dark shadow">
                            <div className="card-body text-center">
                                <h5 className="card-title pb-2">Account Verification</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Please wait for the admin to verify your acount before continuing</h6>
                                    <Link to="/signin" className="btn btn-primary mt-3">Sign In Here</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Verify