import React from "react";
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends React.Component {
  render() {

    if (localStorage.getItem("amb-token")) {
      if (!window.location.pathname.includes("/dashboard"))
        return <Redirect to="/dashboard" />;
      else return <Route {...this.props} />;
    } else if (window.location.pathname.includes("/dashboard")) {
      return <Redirect to="/signin" />;
    } else return <Route {...this.props} />;
  }
}

export default PrivateRoute;
